import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-gray-900 fixed w-full z-10 top-0 shadow-lg">
      <div className="container mx-auto flex items-center justify-between p-5">
        <a className="text-white text-2xl font-bold" href="#">Portfolio</a>
        <div className="lg:hidden">
          <button 
            onClick={toggleMenu} 
            className="flex items-center px-3 py-2 border rounded text-gray-300 border-gray-400 hover:text-white hover:border-white"
          >
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>
        </div>
        <div className="hidden lg:flex lg:items-center lg:w-auto w-full">
          <div className="text-sm lg:flex-grow">
            <a className="block mt-4 lg:inline-block lg:mt-0 text-gray-300 hover:text-white mr-4" href="#about" onClick={closeMenu}>O mnie</a>
            <a className="block mt-4 lg:inline-block lg:mt-0 text-gray-300 hover:text-white mr-4" href="#services" onClick={closeMenu}>Czym się zajmuję</a>
            <a className="block mt-4 lg:inline-block lg:mt-0 text-gray-300 hover:text-white mr-4" href="#technologies" onClick={closeMenu}>Technologie</a>
            <a className="block mt-4 lg:inline-block lg:mt-0 text-gray-300 hover:text-white mr-4" href="#projects" onClick={closeMenu}>Realizacje</a>
            <a className="block mt-4 lg:inline-block lg:mt-0 text-gray-300 hover:text-white mr-4" href="#certifications" onClick={closeMenu}>Certyfikaty</a>
            <a className="block mt-4 lg:inline-block lg:mt-0 text-gray-300 hover:text-white" href="#contact" onClick={closeMenu}>Kontakt</a>
          </div>
        </div>
      </div>
      <motion.div
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        variants={{
          open: { height: 'auto', opacity: 1 },
          closed: { height: 0, opacity: 0 }
        }}
        className="lg:hidden overflow-hidden"
      >
        <div className="text-sm">
          <a className="block px-4 py-2 border-b border-gray-700 text-gray-300 hover:text-white" href="#about" onClick={closeMenu}>O mnie</a>
          <a className="block px-4 py-2 border-b border-gray-700 text-gray-300 hover:text-white" href="#services" onClick={closeMenu}>Czym się zajmuję</a>
          <a className="block px-4 py-2 border-b border-gray-700 text-gray-300 hover:text-white" href="#technologies" onClick={closeMenu}>Technologie</a>
          <a className="block px-4 py-2 border-b border-gray-700 text-gray-300 hover:text-white" href="#projects" onClick={closeMenu}>Realizacje</a>
          <a className="block px-4 py-2 border-b border-gray-700 text-gray-300 hover:text-white" href="#certifications" onClick={closeMenu}>Certyfikaty</a>
          <a className="block px-4 py-2 text-gray-300 hover:text-white" href="#contact" onClick={closeMenu}>Kontakt</a>
        </div>
      </motion.div>
    </nav>
  );
};

export default Navbar;
