import React from "react";

const Services = ({ services, language }) => {
  const description =
    language === "pl" ? services.description_pl : services.description_en;
  const list = language === "pl" ? services.list_pl : services.list_en;

  return (
    <div id="services" className="section">
      <div className="container mx-auto text-center">
        <div className="section-content max-w-2xl mx-auto">
          <h2 className="text-3xl font-bold mb-4">
            {language === "pl" ? "Czym się zajmuję" : "Services"}
          </h2>
          <p>{description}</p>
          <ul className="list-disc list-inside mt-4 text-left">
            {list.map((item, index) => (
              <li key={index} className="animate__animated animate__fadeInUp">
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Services;
