import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Certifications = ({ certifications, language }) => {
  const [showAll, setShowAll] = useState(false);
  const description = language === 'pl' ? certifications.description_pl : certifications.description_en;
  const list = certifications.list;

  const visibleCerts = showAll ? list : list.slice(0, 2);

  return (
    <div id="certifications" className="section bg-gray-900 parallax">
      <div className="container mx-auto text-center px-4 md:px-8">
        <div className="section-content max-w-5xl mx-auto">
          <h2 className="text-4xl font-bold mb-8 text-gray-100">Certyfikaty</h2>
          <p className="mb-8 text-gray-400">{description}</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {visibleCerts.map((cert, index) => (
              <motion.div
                key={index}
                className="cert-item flex items-center bg-gray-800 rounded-lg shadow-lg p-5"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <LazyLoadImage
                  alt={cert.title}
                  src={cert.logo}
                  effect="blur"
                  className="w-16 h-16 mr-4"
                />
                <div className="text-left">
                  <h3 className="text-2xl font-bold mb-1 text-gray-100">{cert.title}</h3>
                  <p className="text-gray-400">{cert.issuer}</p>
                  <p className="text-gray-400">{cert.issuedDate}</p>
                </div>
              </motion.div>
            ))}
          </div>
          {!showAll && (
            <button
              onClick={() => setShowAll(true)}
              className="mt-8 px-6 py-3 bg-blue-500 text-white font-bold rounded-lg shadow-lg hover:bg-blue-700 transition duration-300"
            >
              Pokaż wszystko
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Certifications;
