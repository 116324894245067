import React from 'react';

const Contact = ({ contact, language }) => {
  return (
    <div id="contact" className="section bg-gray-900 parallax" style={{ backgroundImage: "url('/images/contact-bg.jpg')" }}>
      <div className="container mx-auto text-center text-gray-100 px-4 md:px-8">
        <div className="section-content max-w-2xl mx-auto">
          <h2 className="text-4xl font-bold mb-4">{language === 'pl' ? 'Kontakt' : 'Contact'}</h2>
          <p className="mb-8">{language === 'pl' ? 'Jeśli masz pytania lub chcesz nawiązać współpracę, skontaktuj się ze mną:' : 'If you have any questions or want to collaborate, contact me:'}</p>
          <div className="flex justify-center space-x-8">
            <a href={`mailto:${contact.email}`} title="Email" className="text-gray-300 hover:text-white transition ease-in-out duration-300">
              <i className="fas fa-envelope text-4xl"></i>
            </a>
            <a href={contact.linkedin} target="_blank" rel="noopener noreferrer" title="LinkedIn" className="text-gray-300 hover:text-white transition ease-in-out duration-300">
              <i className="fab fa-linkedin text-4xl"></i>
            </a>
            <a href={contact.github} target="_blank" rel="noopener noreferrer" title="GitHub" className="text-gray-300 hover:text-white transition ease-in-out duration-300">
              <i className="fab fa-github text-4xl"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
