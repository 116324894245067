import React from "react";

const Floating = () => {
  return (
    <>
      <div
        className="floating floating-1"
        style={{ backgroundImage: "url('/images/floating1.webp')" }}
      ></div>
      <div
        className="floating floating-2"
        style={{ backgroundImage: "url('/images/floating2.webp')" }}
      ></div>
      <div
        className="floating floating-3"
        style={{ backgroundImage: "url('/images/floating3.webp')" }}
      ></div>
    </>
  );
};

export default Floating;
