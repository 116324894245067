import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Projects = ({ projects, language }) => {
  const description = language === 'pl' ? projects.description_pl : projects.description_en;
  const list = language === 'pl' ? projects.list_pl : projects.list_en;

  return (
    <div id="projects" className="section parallax" style={{ backgroundImage: "url('/images/projects-bg.jpg')" }}>
      <div className="container mx-auto text-center px-4 md:px-8">
        <div className="section-content max-w-5xl mx-auto">
          <h2 className="text-4xl font-bold mb-8 text-gray-100">{language === 'pl' ? 'Realizacje' : 'Projects'}</h2>
          <p className="mb-8">{description}</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {list.map((project, index) => (
              <div key={index} className="project-card bg-gray-800 rounded-lg shadow-lg overflow-hidden animate__animated animate__fadeInUp" data-aos="fade-up">
                <LazyLoadImage
                  alt={project.title}
                  src={`/images/${project.image}`}
                  effect="blur"
                  className="w-full h-64 object-cover"
                />
                <div className="p-5">
                  <h3 className="text-2xl font-bold mb-2 text-gray-100">{project.title}</h3>
                  <p className="text-gray-400 mb-4">{project.description}</p>
                  <a href={project.link} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">{language === 'pl' ? 'Zobacz więcej' : 'Learn more'}</a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
