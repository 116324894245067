import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Floating from './components/Floating';
import About from './components/About';
import Services from './components/Services';
import Technologies from './components/Technologies';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Certifications from './components/Certifications';
import Blog from './components/Blog';
import content from './data/content.json';

const App = () => {
  const [language, setLanguage] = useState('pl');
  const [years, setYears] = useState(0);

  useEffect(() => {
    const startYear = 2020;
    const currentYear = new Date().getFullYear();
    setYears(currentYear - startYear);
  }, []);

  return (
    <Router>
      <div>
        <Navbar />
        <Floating />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <About about={content.about} years={years} language={language} />
                <Services services={content.services} language={language} />
                <Technologies technologies={content.technologies} language={language} />
                <Projects projects={content.projects} language={language} />
                <Certifications certifications={content.certifications} language={language} />
                <Blog language={language} />
                <Contact contact={content.contact} language={language} />
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
