import React from 'react';
import { useInView } from 'react-intersection-observer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const About = ({ about, years, language }) => {
  const description = language === 'pl' ? about.description_pl : about.description_en;
  const replacedDescription = description.replace('{{years}}', years);

  const skills = [
    { name: language === 'pl' ? 'HTML/CSS' : 'HTML/CSS', level: 90 },
    { name: language === 'pl' ? 'JavaScript' : 'JavaScript', level: 80 },
    { name: language === 'pl' ? 'React' : 'React', level: 85 },
    { name: language === 'pl' ? 'Node.js' : 'Node.js', level: 75 },
  ];

  const { ref: skillsRef, inView: skillsInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div id="about" className="section bg-gray-900 parallax" style={{ backgroundImage: "url('/images/about-bg.jpg')" }}>
      <div className="container mx-auto text-center">
        <LazyLoadImage
          alt="Moje zdjęcie"
          src="/images/profile.jpg"
          effect="blur"
          className="profile-img shadow-lg"
        />
        <div className="section-content max-w-4xl mx-auto mt-8 text-gray-100 px-4 md:px-8">
          <h2 className="text-4xl font-bold mb-4">{language === 'pl' ? 'O mnie' : 'About Me'}</h2>
          <p className="text-justify mb-8">{replacedDescription}</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-gray-800 rounded-lg shadow-lg p-5" ref={skillsRef}>
              <h3 className="text-2xl font-bold mb-4">{language === 'pl' ? 'Umiejętności' : 'Skills'}</h3>
              <ul>
                {skills.map((skill, index) => (
                  <li key={index} className="mb-4">
                    <div className="flex justify-between mb-1">
                      <span>{skill.name}</span>
                      <span>{skill.level}%</span>
                    </div>
                    <div className="relative w-full h-4 bg-gray-700 rounded">
                      <div 
                        className={`absolute top-0 left-0 h-4 bg-blue-500 rounded transition-width duration-1000 ${skillsInView ? 'w-full' : 'w-0'}`}
                        style={{ width: skillsInView ? `${skill.level}%` : '0%' }}
                      ></div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-gray-800 rounded-lg shadow-lg p-5">
              <h3 className="text-2xl font-bold mb-4">{language === 'pl' ? 'Zainteresowania' : 'Interests'}</h3>
              <ul className="list-disc list-inside">
                <li>{language === 'pl' ? 'Programowanie' : 'Programming'}</li>
                <li>{language === 'pl' ? 'Sztuczna inteligencja' : 'Artificial Intelligence'}</li>
                <li>{language === 'pl' ? 'Programowanie mikrokontrolerów' : 'Microcontroller Programming'}</li>
                <li>{language === 'pl' ? 'Elektronika' : 'Electronics'}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
