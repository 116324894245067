import React from "react";

const Technologies = ({ technologies, language }) => {
  const description =
    language === "pl"
      ? technologies.description_pl
      : technologies.description_en;

  return (
    <div id="technologies" className="section bg-gray-900">
      <div className="container mx-auto text-center">
        <div className="section-content max-w-2xl mx-auto">
          <h2 className="text-3xl font-bold mb-4">
            {language === "pl" ? "Technologie" : "Technologies"}
          </h2>
          <p>{description}</p>
          <div className="flex flex-wrap justify-center mt-4">
            {technologies.list.map((tech, index) => (
              <img
                key={index}
                src={tech.logo}
                alt={tech.name}
                className="m-2 w-12 h-12 animate__animated animate__zoomIn"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technologies;
