import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Blog = ({ language }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://blog.priefer.pl/wp-json/wp/v2/posts');
        setPosts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div id="blog" className="section bg-gray-900 parallax">
      <div className="container mx-auto text-center px-4 md:px-8">
        <div className="section-content max-w-5xl mx-auto">
          <h2 className="text-4xl font-bold mb-8 text-gray-100">Blog</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {posts.map((post, index) => (
              <motion.div
                key={index}
                className="blog-post bg-gray-800 rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <a href={post.link} target="_blank" rel="noopener noreferrer">
                  <LazyLoadImage
                    alt={post.title.rendered}
                    src={post.featured_media_url}  // Musisz dodać wsparcie dla obrazków w WP API
                    effect="blur"
                    className="w-full h-64 object-cover"
                  />
                  <div className="p-5">
                    <h3 className="text-2xl font-bold mb-2 text-gray-100" dangerouslySetInnerHTML={{ __html: post.title.rendered }}></h3>
                    <p className="text-gray-400 mb-4">{new Date(post.date).toLocaleDateString()}</p>
                    <p className="text-gray-400" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}></p>
                  </div>
                </a>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
